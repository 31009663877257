import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Camera from '../views/Camera.vue'
import Statistic from '../views/Statistics'
import Signup from '../views/Signup'
import Login from '../views/Login'
import Settings from '../views/Settings'
import Lighton from '../views/Lighton'
import Add from '../views/Add'
import Water from '../views/Water'
import About from '../views/About'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/camera',
    name: 'Camera',
    component: Camera
  },
  {
    path: '/statistics',
    name: 'Statstics',
    component: Statistic
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/lighton',
    name: 'Lighton',
    component: Lighton
  },
  {
    path: '/water',
    name: 'Water',
    component: Water
  },
  {
    path: '/add',
    name: 'Add',
    component: Add
  },
  {
    path: '/about',
    name: 'About',
    component: About
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
