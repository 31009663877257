<template>
  <div class="container">
    <v-row>
      <v-card width="420"
              height="150"
              class="mx-auto">
        <v-card-text class="pb-0">
          <p class="display-1 text--primary">
            <v-icon left>insert_chart_outlined</v-icon>
            Statistics
          </p>
        </v-card-text>
        <v-card-actions>
          <v-row align="center"
                 justify="center">
            <v-btn-toggle rounded
                          color="E1E2E3"
                          dense>
              <v-btn x-large class="white"
                     @click="changeTimeframe('12h')">
                12 Hours
              </v-btn>
              <v-btn x-large class="white"
                     @click="changeTimeframe('24h')">
                Day
              </v-btn>
              <v-btn x-large class="white"
                     @click="changeTimeframe('7d')">
                Week
              </v-btn>
              <v-btn x-large class="white"
                     @click="changeTimeframe('1m')">
                Month
              </v-btn>
            </v-btn-toggle>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-row>
    <div class="row mt-5" v-if="moistureData.length > 0">
      <div class="col">
        <v-card width="100%"
                height="100%"
                class="mx-auto">
          <v-sparkline
            :key="moisture"
            :smooth="16"
            :gradient="['green', 'yellow', 'red']"
            :line-width="3"
            :value="moistureData"
            height="100%"
            auto-draw
            stroke-linecap="round">
          </v-sparkline>
          <h3 class="text-center">Moisture</h3>
        </v-card>
      </div>
    </div>
    <div class="row mt-5" v-if="pumpData.length > 0">
      <div class="col">
        <v-card width="100%"
                height="100%"
                style="padding-top: 15px;"
                class="mx-auto">
          <v-sparkline
            :key="pump"
            :smooth="2"
            :gradient="['#6FAAF4', '#32177F']"
            :line-width="3"
            :value="pumpData"
            type="bar"
            :padding="3"
            height="100%"
            auto-draw
            stroke-linecap="round">
          </v-sparkline>
          <h3 class="text-center">Water given</h3>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  data: () => ({
    moistureData: [],
    pumpData: [],
    timeframe: '7d',
  }),
  computed: {
    currentPlantUlid() {
      return this.$store.state.currentPlantUlid
    }
  },
  methods: {
    changeTimeframe(timeframe) {
      this.timeframe = timeframe
      this.getChartData()
    },
    async getChartData() {
      let ulid = this.currentPlantUlid
      let res = await axios.get('https://growbot.sonnenstreifen.de/api/plants/' + ulid + "/" + this.timeframe)
      let moistureData = res.data.response.moisture
      let pumpData = res.data.response.pump
      this.moistureData = []
      this.pumpData = []
      moistureData.forEach(data => {
        this.moistureData.push(Number(data.percent))
      })
      pumpData.forEach(data => {
        this.pumpData.push(Number(data.pump))
      })
    }
  },
  mounted() {
    this.getChartData()
  }
}
</script>
