<template>
    <v-menu
    dark
    bottom right>
    <template v-slot:activator="{ on, attrs }">
        <v-btn
        dark
        icon
        v-bind="attrs"
        v-on="on"
        >
        <v-icon color="black">mdi-dots-vertical</v-icon>
        </v-btn>
    </template>

    <v-list
    class="menu">
        <v-list-item
        v-for="(item, i) in items"
        :key="i"
        router :to="item.route"
        >
        <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
        </v-list-item>
    </v-list>
    </v-menu>
</template>

<style>
.menu{
  background-color: #2C3C36 !important;
}
</style>

<script>

  export default {
    data: () => ({
      items: [
        { icon: 'mdi-water-outline', title: 'Water', route: '/water' },
        { icon: 'wb_sunny', title: 'Light', route: '/lighton' },
        { icon: 'camera_alt', title: 'Camera', route: '/camera' },
        { icon: 'insert_chart_outlined', title: 'Statistics', route: '/statistics' },
        { icon: 'settings', title: 'Settings', route: '/settings' },
      ],
    }),
  }
</script>