<template>
 <v-container>
    <v-expand-transition>
            <v-card
            v-show="expand"
            height="100"
            width="100"
            class="mx-auto">
                <v-img
                src="../assets/watered.png"
                aspect-ratio="1"      
                ></v-img>
            </v-card>
    </v-expand-transition>
    <v-row>

      <v-card
        width="400"
        height="150"
        class="mx-auto"
      >
    <v-card-text class="pb-0">
      <p class="display-1 text--primary">
        <v-icon>mdi-water-outline</v-icon>
        Water
      </p>
      </v-card-text>
       <v-card-actions>
              <v-row
                align="center"
                justify="center"
              >
                <v-btn-toggle
                  mandatory
                  color="E1E2E3"
                >
                  <v-btn x-large class="white" @click="expand = !expand; waterOn">Water your Plant</v-btn>
                </v-btn-toggle>
                </v-row>
              </v-card-actions>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'

  export default {
    data: () => ({
        expand: false,
        methods: {
            async waterOn () {
            await axios.get('+ /water on')
            }
        }
    }),
  }
</script>
