<template>
  <v-container>
    <v-row>
      <v-card
        width="400"
        height="150"
        class="mx-auto"
      >
    <v-card-text class="pb-0">
      <p class="display-1 text--primary">
        <v-icon left>wb_sunny</v-icon>
        Light
      </p>
      </v-card-text>
       <v-card-actions>
              <v-row
                align="center"
                justify="center"
              >
                <v-btn-toggle
                  mandatory
                  color="E1E2E3"
                  
                >
                  <v-btn x-large class="white" @click="lightOn">On</v-btn>
                  <v-btn x-large class="white" @click="lighOff">Off</v-btn>
                </v-btn-toggle>
                </v-row>
              </v-card-actions>
            </v-card>
        </v-row>
    </v-container>
</template>


<script>
import axios from 'axios'

export default {
  methods: {
    async lightOn () {
      await axios.get('+ /light on')
    },
    async lightOff () {
      await axios.get('+ /light off')
    }
  }
}
</script>