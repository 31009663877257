<template>	
  <div class="about" style="text-align:center;">
	
		<h1>About your Growbot application</h1>
		<p>
			You found your way to the about page of the GrowBot app. <br>
			This app makes it easy to controll your device.
			<br><br>
			<b>To see the options you have for a plant press the menu button in 
			the upper right corner of the card.</b> <br><br>
			<img src="../assets/menu.png" alt="Menu" style="max-width:343px;"> <br> <br>
			<b>You then have the option to choose:</b> <br>
		</p>

		<div style="display: inline-block;">
			<img src="../assets/menu2.png" alt="Menu" style="max-width:343px;float:left;">
		
			<ol type="1" style="max-width:300px;float:left;text-align:left;">
  <!-- eslint-disable no-mixed-spaces-and-tabs -->				<li><b>Water</b> <br> <!-- eslint-disable no-mixed-spaces-and-tabs -->
				The option of watering your plant manually.
				</li>
  <!-- eslint-disable no-mixed-spaces-and-tabs -->			<li><b>Light</b> <br>  <!-- eslint-disable no-mixed-spaces-and-tabs -->
				A light switch for your lamp.
				</li>
  				<li><b>Camera</b> <br>
				The option of taking a live picture of your plant, 
				which will be saved in a gallery.
				</li>
  				<li><b>Statistics</b><br>
				A graph about your plants moisture and the pump usage by 12 hours, day, week or month.
				</li>
  				<li><b>Settings</b><br>
				Here you do fine-tuning and setup the automation.
				</li>
			</ol>
		</div>
		<h2 style="float:down;">Settings</h2>
		
		<div style="display: inline-block;">
			<img src="../assets/settings.png" alt="Menu" style="max-width:343px;float:left;">
			
			<ol type="1" style="max-width:300px;float:left;text-align:left;">
  				<li><b>How often do you want your plant to be watered?</b><br>
				Sets how often the plant is watered per day.
				</li>
  				<li><b>Expert Settings</b><br>
				In "Expert-Mode" the plant watering only starts 
				once the moisture is under a certain minimum (e.g. 40%).
				Then the plant gets watered regularly until the moisture reaches a maximum(e.g. 60%). 
				After reaching this maximum GrowBot will wait again until the minimum is reached.
				</li>
			</ol>
		</div>
 	
	</div>
</template>

<script>
export default {
  /* eslint-disable no-mixed-spaces-and-tabs */
}
</script>