<template>
  <v-form>
    <v-container>
      <v-row>
        <v-card width="400"
                class="mx-auto">
          <v-card-text class="pb-0">
            <div style="width: 128px; margin-left: auto; margin-right: auto;">
              <img width="128px" alt="Vue logo" src="../assets/logo.png">
            </div>
            <p class="display-1 text--primary text-center">
              Welcome!
            </p>
          </v-card-text>
          <v-card-actions>
            <v-row align="center"
                   justify="center">
              <v-btn-toggle tile
                            color="teal accent-4"
                            group>
                <v-btn text color="teal accent-4" to="/signup" x-large>Sign up</v-btn>
                <v-btn disabled text color="teal accent-4" x-large >Login</v-btn>
              </v-btn-toggle>
            </v-row>
          </v-card-actions>
          <div class="ma-5 mb-0">
            <v-text-field outlined
                          v-model="username"
                          label="Username" />
            <v-text-field outlined
                          v-model="password"
                          label="Password"
                          type="password"
                          v-on:keyup.enter="login" />
          </div>
          <v-card-actions>
            <v-btn mt-0 block x-large dark
                   class="cyan darken-3 text-center"
                   @click="login">
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  export default {
    data () {
      return {
        username: '',
        password: ''
      }
    },
    methods: {
      login () {
        this.$store.dispatch('login', { username: this.username,
                                        password: this.password })
        .then(response => {
          console.log(response)
        })
      }
    }
  }
</script>
