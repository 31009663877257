<template>
  <v-form>
    <v-container>
      <v-row>
        <v-card width="400" class="mx-auto">
          <v-card-text class="pb-0">
            <p class="display-1 text--primary">
              <v-icon left>settings</v-icon>
              Settings
            </p>
            <div class="mb-0 mt-0">
              <v-text-field label="Name of your Plant" outlined v-model="plantName" />
              <v-sheet class="pa-2">
                <v-switch class="mt-0 mb-0" true-value="On" false-value="Off" color="teal accent-4" v-model="automationActive" inset :label="`Automatic mode: ${automationActive.toString()}`"></v-switch>
              </v-sheet>
            </div>
            <div class="ma-1 mt-0">
              <h3>How much water does your plant need?</h3>
            </div>
          </v-card-text>
          <v-list shaped>
            <v-list-item-group v-model="amount">
              <template v-for="(item, i) in items">
                <v-divider v-if="!item" :key="`divider-${i}`" />
                <v-list-item v-else :key="`item-${i}`" active-class="teal--text text--accent-4">
                  <template v-slot:default="{ active, toggle }">
                    <v-list-item-icon style="width: 64px">
                      <v-icon>{{ item.icon }}</v-icon>
                      <v-icon>{{ item.icon2 }}</v-icon>
                      <v-icon>{{ item.icon3 }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox :input-value="active" :true-value="item.value" color="teal accent-4" @click="toggle" />
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
              <v-card-text class="pb-0">
                <div class="ma-1">
                  <h3 class="mb-6">
                    How often do you want your Plant to be watered?
                  </h3>
                </div>
                <v-row>
                  <v-col class="12 mb-0 pb-0">
                    <v-slider v-model="frequency"
                              thumb-label="always"
                              color="teal accent-4"
                              track-color="teal lighten-4"
                              :max="5"
                              :min="1"
                              ticks="always"
                              tick-size="4">
                    </v-slider>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-expansion-panels flat>
                <v-expansion-panel v-for="(item,i) in 1" :key="i">
                  <v-expansion-panel-header>
                    <h3>Expert Settings</h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card-text style="padding:0">
                      <div>
                        <h5>Moisture range</h5>
                      </div>
                    </v-card-text>
                    <v-row>
                      <v-col>
                        <v-range-slider v-model="range"
                                        :max="max"
                                        :min="min"
                                        hide-details
                                        class="align-center"
                                        color="teal accent-4"
                                        track-color="teal lighten-4">
                          <template v-slot:prepend>
                            <v-text-field :value="minMoisture"
                                          class="mt-0 pt-0"
                                          hide-details
                                          single-line
                                          readonly
                                          style="width: 22px">
                            </v-text-field>
                          </template>
                          <template v-slot:append>
                            <v-text-field :value="maxMoisture"
                                          class="mt-0 pt-0"
                                          hide-details
                                          single-line
                                          readonly
                                          style="width: 22px"
                                          @change="changeRange('max', $event)">
                            </v-text-field>
                          </template>
                        </v-range-slider>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-list-item-group>
            <v-card-text class="pb-0">
              <div class="ma-1">
                <h3>Time Settings</h3>
              </div>
            </v-card-text>
            <v-list shaped>
              <v-list-item-group v-model="watertimes">
                <template v-for="(time, i) in times">
                  <v-divider v-if="!time"
                             :key="`divider-${i}`">
                  </v-divider>
                  <v-list-item v-else
                               :key="`time-${i}`"
                               active-class="teal--text text--accent-4">
                    <template v-slot:default="{ active, toggle }">
                      <v-list-item-icon style="width: 64px">
                        <v-icon style="transform: rotate(180deg)">{{ time.icon }}</v-icon>
                        <v-icon>{{ time.icon2 }}</v-icon>
                        <v-icon>{{ time.icon3 }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="time.name"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox :input-value="active"
                                    :true-value="time"
                                    color="teal accent-4"
                                    @click="toggle">
                        </v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
            <v-card-actions>
              <v-btn @click="saveSettings" mt-0 block x-large dark class="cyan darken-3 text-center">Save Changes</v-btn>
            </v-card-actions>
          </v-list>
        </v-card>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import axios from 'axios'

  export default {
    data: () => ({
      items: [
        { value: 0, name: 'Low', icon: 'mdi-water-outline', icon2: '', icon3: ''},
        { value: 1, name: 'Medium', icon: 'mdi-water-outline', icon2: 'mdi-water-outline', icon3:''},
        { value: 2, name: 'High', icon: 'mdi-water-outline', icon2: 'mdi-water-outline', icon3:'mdi-water-outline'}
      ],
      times: [
        { value: 0, name: 'Watering during the Day', icon: 'wb_sunny', icon2: '', icon3: ''},
        { value: 1, name: 'Watering during the Night', icon: 'brightness_2' , icon2: '', icon3:''},
      ],
      plantName: null,
      min: 20,
      max: 80,
      amount: null,
      frequency: null,
      minMoisture: 40,
      maxMoisture: 60,
      watertimes: null,
      automationActive: 'On',
    }),
    methods: {
      season (val) {
        return this.icons[val]
      },
      saveSettings() {
        axios.post('https://growbot.sonnenstreifen.de/api/plants/' + this.currentPlantUlid + '/settings',
          { name: this.plantName,
            automation_active: (this.automationActive == 'On') ? 1 : 0,
            water_needed: this.amount,
            water_frequency: this.frequency,
            moisture_min: this.minMoisture,
            moisture_max: this.maxMoisture,
            water_only_at_night: this.watertimes
        }).then(res => {
          if (res.data.status == 'success') {
            this.$router.push({ name: 'Home' })
          }
        })
      },
      changeRange(side, value) {
        if (side == 'min') {
          this.range[0] = value
        } else {
          this.range[1] = value
        }
        console.log(value)
      },
      setAmountCheckboxes(value) {
        if (value <= 50) {
          this.amount = 0
          console.log('setting amount to low')
        } else if (value <= 60) {
          this.amount = 1
          console.log('setting amount to medium')
        } else {
          this.amount = 2
          console.log('setting amount to high')
        }
      }
    },
    computed: {
      range: {
        get () {
          let range = [40, 60]
          if (this.amount == 0) {
            range[1] = 50
            switch (this.frequency) {
              case 1:
              range[0] = 30
              break
              case 2:
              range[0] = 34
              break
              case 3:
              range[0] = 38
              break
              case 4:
              range[0] = 42
              break
              case 5:
              range[0] = 46
              break
            }
          }
          if (this.amount == 1) {
            range[1] = 60
            switch (this.frequency) {
              case 1:
              range[0] = 35
              break
              case 2:
              range[0] = 40
              break
              case 3:
              range[0] = 45
              break
              case 4:
              range[0] = 50
              break
              case 5:
              range[0] = 55
              break
            }
          }
          if (this.amount == 2) {
            range[1] = 70
            switch (this.frequency) {
              case 1:
              range[0] = 40
              break
              case 2:
              range[0] = 46
              break
              case 3:
              range[0] = 52
              break
              case 4:
              range[0] = 58
              break
              case 5:
              range[0] = 64
              break
            }
          }
          return range
        },
        set (value) {
          this.minMoisture = value[0]
          this.maxMoisture = value[1]
        }
      },
      currentPlantUlid() {
        return this.$store.state.currentPlantUlid
      }
    },
    watch: {
      range() {
        this.minMoisture = this.range[0]
        this.maxMoisture = this.range[1]
      }
    },
    async mounted() {
      axios.get('https://growbot.sonnenstreifen.de/api/plants/' + this.currentPlantUlid + '/settings')
        .then(res => {
          this.plantName = res.data.response.name
          this.automationActive = (Number(res.data.response.automation_active) == 1) ? 'On' : 'Off'
          this.frequency = Number(res.data.response.water_frequency)
          this.amount = Number(res.data.response.water_needed)
          this.range[0] = Number(res.data.response.moisture_min)
          this.range[1] = Number(res.data.response.moisture_max)
          this.watertimes = Number(res.data.response.water_only_at_night)
        })
    },
  }
</script>
