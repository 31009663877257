import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  iconfont: 'md',
  theme: {
    primary: '#E5E5E5',
    success: '#B5EEE4',
    info: '#458E8F',
    error: '#FCEC5D'
  }
});
