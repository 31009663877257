<template>
  <v-container>
    <v-row>
      <v-card
        width="400"
        height="70"
        class="mx-auto">
        <v-card-text class="pb-0">
          <p class="display-1 text--primary">
            <v-icon left>camera_alt</v-icon>
            Camera
          </p>
        </v-card-text>
      </v-card>

      <v-col cols="12" sm="6" offset-sm="3">
        <v-card>
          <v-container fluid>
            <v-row>
              <v-col>
                <v-card flat tile class="d-flex">
                  <v-img
                    :src="imgurl"
                    :lazy-src="imgurl"
                    aspect-ratio="1"
                    class="grey lighten-2">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>

      <!-- <v-card
        width="400"
        class="mx-auto mt-2 pa-3">
        <v-card-text class="pb-0">
          <p class="display-1 text--primary">
            <v-icon left>collections</v-icon>
            Gallery
          </p>
        </v-card-text>
        <v-card-actions>
          <v-row
            align="center"
            justify="center">
            <v-btn-toggle
              rounded
              color="E1E2E3">
              <v-btn x-large class="white">Hour</v-btn>
              <v-btn x-large class="white">Day</v-btn>
              <v-btn x-large class="white">Month</v-btn>
            </v-btn-toggle>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row>
            <v-col cols="12" sm="6" offset-sm="3">
              <v-card flat>
                <v-container fluid>
                  <v-row>
                    <v-col
                      v-for="n in 9"
                      :key="n"
                      class="d-flex child-flex"
                      cols="4">
                      <v-card flat tile class="d-flex">
                        <v-img
                          :src="`https://picsum.photos/500/300?image=${n * 5 + 10}`"
                          :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
                          aspect-ratio="1"
                          class="grey lighten-2">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center">
                              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      timestamp: 42,
    }
  },
  computed: {
    currentPlantUlid() {
      return this.$store.getters.currentPlantUlid
    },
    imgurl() {
      return 'https://growbot.sonnenstreifen.de/pictures/'
              + this.currentPlantUlid + '/live.jpg?'
              + this.timestamp
    },
  },
  methods: {
    changeTimestamp() {
      this.timestamp = performance.now()
    }
  },
  mounted: function () {
    window.setInterval(() => {
      this.changeTimestamp()
    }, 60000)
  }
}
</script>

<style scoped>
  #picStyle {
    position: absolute;
    top: 120px;
    right: -323px;
  }
</style>
