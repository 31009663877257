<template>
  <v-app>
    <Navbar />
    <v-main class="grey lighten-3">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from './components/Navbar'

export default {
  name: 'App',
  components: { Navbar },
  created () {
    this.$store.dispatch('AutoLogin')
  },
  beforeCreate () {
    if (this.$store.state.token == false) {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>
