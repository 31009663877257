<template>
  <v-row align="center"
         justify="center"
         color="grey-lighten-2"
         style="margin-right:0; margin-left:0;">
    <v-col cols="12">
      <v-card :elevation="6"
              class="mx-auto mb-4 rounded-lg"
              width="343px"
              height="auto"
              v-for="plant in plants" :key="plant.ulid">
        <v-img style="border-radius: 8px"
               src="../assets/Monstera.png">
          <div class="pa-5">
            <h1 class="font-weight-regular">{{ plant.name }}</h1>
            <h5 class="mt-3" v-if="Number(plant.moisture) < 40">Your plant needs water!</h5>
            <h5 class="mt-3" v-else>Everything is good!</h5>
            <h6 class="mt-3">Moisture: {{ plant.moisture }} %</h6>
            <h6 class="mt-3">
              Last time watered: {{ plant.lastPumprun }}
            </h6>
          </div>
        </v-img>
        <v-tooltip v-if="Number(plant.automationStatus) == 1" color="cyan darken-3" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-8"
                   absolute
                   right top icon>
              <v-icon color="black"
                      v-bind="attrs"
                      v-on="on">
                mdi-water
              </v-icon>
            </v-btn>
          </template>
          <span>Automatic</span>
        </v-tooltip>
        <v-tooltip v-else color="cyan darken-3" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-8"
                   absolute
                   right top icon>
              <v-icon color="black"
                      v-bind="attrs"
                      v-on="on">
                mdi-water-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Manual</span>
        </v-tooltip>
        <v-btn absolute=""
               right
               top
               icon color="black">
          <settings />
        </v-btn>
      </v-card>
      <v-card disabled
              :elevation="6"
              class="mx-auto rounded-lg mt-4"
              width="343px"
              height="auto">
        <v-img src="../assets/NextPlant.jpg"
               style="border-radius: 8px">
          <div class="pa-5">
            <h1 class="font-weight-regular">Coming soon</h1>
            <h5 class="mt-3 mb-0">Everything is good!</h5>
            <h6 class="mt-3 mb-0">Moisture: 99%</h6>
            <h6 class="mt-3 mb-0">Last time watered 2 hours ago</h6>
          </div>
        </v-img>
      </v-card>
      <div class="text-center">
        <v-btn to="/add" x-large class="ma-3" outlined color="#1F7A8C" disabled><v-icon>add</v-icon> Add a new Plant</v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import settings from '../components/settings-dropdown';
import axios from 'axios'

export default {
  name: 'settings-dropdown',
  components: { settings },

  data () {
    return {
      plants: [],
    }
  },
  async mounted() {
    axios
      .get('https://growbot.sonnenstreifen.de/api/plants')
      .then(res => {
        this.plants = res.data.response
        this.$store.commit('setCurrentPlantUlid', res.data.response[0].ulid)
      })
  }
}
</script>
