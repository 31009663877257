<template>
  <v-form>
    <v-container>
      <v-row>
        <v-card width="400" class="mx-auto">
          <v-card-text class="pb-0">
            <div style="width: 128px; margin-left: auto; margin-right: auto;">
              <img width="128px" alt="Vue logo" src="../assets/logo.png">
            </div>
            <p class="display-1 text--primary text-center">
              Welcome!
            </p>
          </v-card-text>
          <v-card-actions>
            <v-row align="center" justify="center">
              <v-btn-toggle v-model="text" color="teal accent-4" group>
                <v-btn disabled text color="teal accent-4" x-large >Sign up</v-btn>
                <v-btn text color="teal accent-4" to="/login" x-large >Login</v-btn>
              </v-btn-toggle>
            </v-row>
          </v-card-actions>
          <div class="coming-soon">
            <h3>coming soon</h3>
          </div>
          <div class="ma-5">
            <h3>Create a Growbot Account</h3>
          </div>
          <div class="ma-5 mb-0">
            <v-text-field label="First Name" outlined />
            <v-text-field label="Last Name" outlined />
          </div>
          <h4 class="ma-5 mt-0 mb-0">Enter your Username</h4>
          <div class="ma-5 mb-0">
            <v-text-field label="Username" outlined />
          </div>
          <h4 class="ma-5 mt-0">Enter your Password</h4>
          <div class="ma-5 mb-0">
            <v-text-field v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="password"
              label="Password"
              hint="At least 8 characters"
              counter
              @click:append="show1 = !show1"
              outlined />
            <v-text-field v-model="confirmpswd"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="confirmpswd"
              label="Confirm Password"
              hint="At least 8 characters"
              counter
              @click:append="show1 = !show1"
              outlined />
          </div>
          <v-card-actions>
            <v-btn mt-0 block x-large dark class="cyan darken-3 text-center">
              Create an Account
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  export default {
    data () {
      return {
        show1: false,
        password: '',
        confirmpswd: '',
        rules: {
          required: value => !!value || 'Required.',
          min: value => value.length >= 8 || 'Min 8 characters',
          emailMatch: () => ('The email and password you entered don\'t match'),
        },
      }
    },
  }
</script>

<style>
  .coming-soon {
    position: absolute;
    width: 100%;
    height: 71.5%;
    background-color: #ffffffdd;
    z-index: 99;
  }

  .coming-soon h3 {
    text-align: center;
    margin-top: 50%;
  }
</style>
