<template>
    <nav>
        <v-app-bar
        app
        color="white"
        height="64px"
        >
            <v-icon class="black--text" @click="drawer = !drawer">fa fa-bars</v-icon>

            <v-spacer/>

            <v-btn rounded text color="white" to="/">
                <img alt="Vue logo" src="../assets/logo-nav.png">
            </v-btn>

            <v-spacer/>


            <v-icon v-if='auth' small right class="black--text" @click="onLogout">fa fa-sign-out</v-icon>
            <v-icon v-else small right class="black--text" @click="onLogout" color="white">	fa fa-square</v-icon>

        </v-app-bar>

        <v-navigation-drawer v-model="drawer" app>
            <v-btn
            depressed
            block
            to="/about"
            >
            About
            </v-btn>
        </v-navigation-drawer>

    </nav>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            drawer: false
        }
    },
    computed: {
      auth () {
        return this.$store.getters.ifAuthenticated
      }
    },
    methods: {
      async onLogout() {
        axios.get('https://growbot.sonnenstreifen.de/api/auth/logout')
        this.$store.dispatch('logout')
      }
    }

}
</script>
