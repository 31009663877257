import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from './router';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    userId: null,
    user: null,
    currentPlantUlid: null
  },
  mutations: {
    authUser (state, userData) {
      state.token = userData.token
      state.session = userData.session
      state.userId = userData.userId
    },
    clearAuth (state) {
      state.token = null
      state.userId = null
    },
    setCurrentPlantUlid (state, ulid) {
      console.log('setting current plant ulid to: ' + ulid)
      state.currentPlantUlid = ulid
    },
  },
  actions: {
    login ({commit}, authData) {
      axios.post('https://growbot.sonnenstreifen.de/api/auth/login', {
        username: authData.username,
        password: authData.password,
      })
        .then(res => {
          localStorage.setItem('token', res.data.response.token)
          localStorage.setItem('session', res.data.response.session)
          localStorage.setItem('userId', res.data.response.id)
          commit('authUser', {
            token: res.data.response.token,
            session: res.data.response.session,
            userId: res.data.response.id
          })
          router.push("/")
        })
        .catch(error => console.log(error))
    },
    logout ({commit}) {
      commit('clearAuth')
      localStorage.removeItem('token')
      localStorage.removeItem('session')
      localStorage.removeItem('userId')
      router.replace('/login')
    },
    AutoLogin ({commit}) {
      const token = localStorage.getItem('token')
      if (!token) {
        router.replace('/login')
        return
      }
      const userId = localStorage.getItem('userId')
      commit('authUser', {
        token: token,
        userId: userId
      })
    }
  },
  getters: {
    user (state) {
      return state.user
    },
    ifAuthenticated (state) {
      return state.token !== null
    },
    currentPlantUlid (state) {
      return state.currentPlantUlid
    }
  }
})
