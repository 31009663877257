<template>
    <v-form>
        <v-container>
            <v-row>
                <v-card width="400" class="mx-auto">
                    <v-card-text class="pb-0">
                        <p class="display-1 text--primary">
                            <v-icon left>settings</v-icon>
                            Settings
                        </p>
                        <div class="mb-0 mt-0">
                        <v-text-field
                            label="Name of your Plant"

                            outlined
                        ></v-text-field>
                            <v-sheet class="pa-2">
                                <v-switch class="mt-0 mb-0" true-value="On" false-value="Off" color="teal accent-4" v-model="switch1" inset :label="`Automatic mode: ${switch1.toString()}`"></v-switch>
                            </v-sheet>
                        </div>
                        <div class="ma-1 mt-0">
                            <h3>How much water does your plant need?</h3>
                        </div>
                    </v-card-text>
                    <v-list shaped>
                        <v-list-item-group v-model="model">
                            <template v-for="(item, i) in items">
                                <v-divider v-if="!item" :key="`divider-${i}`" />
                                <v-list-item v-else :key="`item-${i}`" :value="item.name" active-class="teal--text text--accent-4">
                                    <template v-slot:default="{ active, toggle }">
                                        <v-list-item-icon style="width: 64px">
                                            <v-icon>{{ item.icon }}</v-icon>
                                            <v-icon>{{ item.icon2 }}</v-icon>
                                            <v-icon>{{ item.icon3 }}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.name"></v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-checkbox :input-value="active" :true-value="item" color="teal accent-4" @click="toggle" />
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                            </template>
                            <v-card-text class="pb-0">
                                <div class="ma-1">
                                    <h3 class="mb-6">
                                        How often do you want your Plant to be watered?
                                    </h3>
                                </div>
                                <v-row>
                                    <v-col class="12 mb-0 pb-0">


                                    <v-slider
                                    v-model="slider1"
                                    thumb-label="always"
                                    color="teal accent-4"
                                    track-color="teal lighten-4"
                                    :max="5"
                                    :min="1"
                                    ticks="always"
                                    tick-size="4"

                                    ></v-slider>

                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-expansion-panels text>
                                <v-expansion-panel
                                v-for="(item,i) in 1"
                                :key="i"
                                >
                                <v-expansion-panel-header><h3>Expert Settings</h3></v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-card-text style="padding:0">
                                    <div>
                                        <h5>
                                            Moisture range
                                        </h5>
                                    </div>
                                    </v-card-text>
                                    <v-row>
                                    <v-col>
                                    <v-range-slider
                                        v-model="range"
                                        :max="max"
                                        :min="min"
                                        hide-details
                                        class="align-center"
                                        color="teal accent-4"
                                        track-color="teal lighten-4"
                                    >
                                        <template v-slot:prepend>
                                        <v-text-field
                                            :value="range[0]"
                                            class="mt-0 pt-0"
                                            hide-details
                                            single-line
                                            type="number"
                                            style="width: 60px"
                                            @change="$set(range, 0, $event)"
                                        ></v-text-field>
                                        </template>
                                        <template v-slot:append>
                                        <v-text-field
                                            :value="range[1]"
                                            class="mt-0 pt-0"
                                            hide-details
                                            single-line
                                            type="number"
                                            style="width: 60px"
                                            @change="$set(range, 1, $event)"
                                        ></v-text-field>
                                        </template>
                                    </v-range-slider>
                                    </v-col>
                                </v-row>
                                </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-list-item-group>
                        <v-card-text class="pb-0">
                            <div class="ma-1">
                            <h3>
                                Time Settings
                            </h3>
                            </div>
                        </v-card-text>

                        <v-list shaped>
                        <v-list-item-group
                            v-model="model"
                        >
                            <template v-for="(time, i) in times">
                            <v-divider
                                v-if="!time"
                                :key="`divider-${i}`"
                            ></v-divider>
                                <v-list-item
                                    v-else
                                    :key="`time-${i}`"
                                    :value="time.name"
                                    active-class="teal--text text--accent-4"
                                >
                                <template v-slot:default="{ active, toggle }">
                                    <v-list-item-icon style="width: 64px">
                                        <v-icon style="transform: rotate(180deg)">{{ time.icon }}</v-icon>
                                        <v-icon>{{ time.icon2 }}</v-icon>
                                        <v-icon>{{ time.icon3 }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="time.name"></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-checkbox
                                        :input-value="active"
                                        :true-value="time"
                                        color="teal accent-4"
                                        @click="toggle"
                                        ></v-checkbox>
                                    </v-list-item-action>
                                </template>
                            </v-list-item>
                            </template>
                        </v-list-item-group>
                        </v-list>
                        <v-card-actions>
                            <v-btn mt-0 block x-large dark class="cyan darken-3 text-center">Save Changes</v-btn>
                        </v-card-actions>
                    </v-list>
                </v-card>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
  export default {
        data: () => ({
        items: [
        { name: 'Low', icon: 'mdi-water-outline', icon2: '', icon3: ''},
        { name: 'Medium', icon: 'mdi-water-outline', icon2: 'mdi-water-outline', icon3:''},
        { name: 'High', icon: 'mdi-water-outline', icon2: 'mdi-water-outline', icon3:'mdi-water-outline'}
      ],
       times: [
        { name: 'Watering during the Day', icon: 'wb_sunny', icon2: '', icon3: ''},
        { name: 'Watering during the Night', icon: 'brightness_2' , icon2: '', icon3:''},
       ],



        min: 0,
        max: 100,
        slider1:2,
        slider: 40,
        range: [40, 60],
        switch1: 'On',


    }),
        model: ['High'],
        methods: {
        season (val) {
            return this.icons[val]
        },

    }
    }


  </script>
